// Entry point for the build script in your package.json

import Vue from "vue/dist/vue.esm";

window.addEventListener("DOMContentLoaded", (event) => {
  const el = document.querySelector("#top_navbar");
  if (!el) return;
  new Vue({
    el,
    data() {
      return {
        solutions_flyout_open: false,
        mobileMenuShow: false,
      };
    },
    methods: {
      toggleSolutionsFlyout() {
        this.solutions_flyout_open = !this.solutions_flyout_open;
      },
      toggleMobileMenu() {
        this.mobileMenuShow = !this.mobileMenuShow;
      },
    },
  });
});
